@import "https://fonts.googleapis.com/css2?family=Aldrich&family=Lily+Script+One&family=Poppins:wght@200;500;700;800&family=Roboto:wght@500&display=swap";
:root {
  --bg-color: #222429;
  --primary-bg-color: #fefeffab;
  --primary-color: #2c62f6;
  --text-color: #000;
  --text-active: #000;
  --button-hover-bg-color: #2b2e34;
  --border-color: #fff;
  --dropdown-height: 0;
  --rotate-arrow: 0;
  --translate-value: 0;
  --list-opacity: 0;
  --transition-time: .4s;
  --transition-timing: cubic-bezier(.25, .46, .45, .94);
  --border-radius: 1.4rem;
  --list-button-height: 4.6rem;
  --floating-icon-size: 26;
  --floating-icon-top: 0;
  --floating-icon-left: 0;
}

body {
  background-color: #fff;
  margin: 0;
}

main {
  overflow-x: hidden;
}

.navbar {
  z-index: 6;
  -webkit-backdrop-filter: blur(5px);
  -o-backdrop-filter: blur(5px);
  background-color: #07061000;
  width: 100%;
  height: 6.5rem;
  position: fixed;
  top: 0;
}

.navbar ul {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.navbar li {
  float: right;
  align-items: center;
  padding: 1.5rem 5.5rem;
  list-style-type: none;
  display: flex;
}

.navbar li button a {
  padding: 0;
  margin: 0 !important;
}

.navbar li a {
  color: #000;
  text-transform: uppercase;
  border-radius: .2rem;
  margin: 0 2.5rem;
  padding: .5rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
}

.navbar ul a.logo img {
  width: 7.3rem;
  padding-left: 2.5rem;
}

.navbar li a:not(:last-child):hover {
  color: #3e3514;
  transition: all .5s ease-in-out;
}

.menu-toggle {
  display: none;
}

.dropdown-title-icon, .dropdown-arrow {
  display: inline-flex;
}

.dropdown-title {
  text-transform: uppercase;
  margin: auto 1.8rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.dropdown-button {
  align-items: center;
  padding: 0 1.8rem;
  font-family: Poppins;
  font-weight: 400;
  display: flex;
}

.dropdown-button svg {
  transition: all var(--transition-time) var(--transition-timing);
  fill: #00000075;
  width: 1.6rem;
  height: 1.6rem;
}

.dropdown-button svg, .dropdown-button span {
  pointer-events: none;
}

.dropdown-button:hover, .dropdown-button:focus {
  color: var(--text-active);
}

.dropdown-button:hover svg, .dropdown-button:focus svg {
  fill: var(--text-active);
}

.main-button {
  border-radius: var(--border-radius);
  color: var(--text-color);
  background-color: var(--primary-bg-color);
  border: .1rem solid var(--border-color);
  -webkit-filter: drop-shadow(0 0 .75rem #1b18182b);
  -ms-filter: drop-shadow(0 0 .75rem #1b18182b);
  transition: all var(--transition-time) var(--transition-timing);
  padding: 1rem .8rem;
}

.main-button:focus {
  border: .1rem solid var(--primary-color);
  box-shadow: 0 0 0 .2rem #2c62f666;
}

.main-button .dropdown-arrow {
  transition: transform var(--transition-time) var(--transition-timing);
  transform: rotate(var(--rotate-arrow));
  margin-left: 1.8rem;
}

.dropdown-list-container {
  max-height: var(--dropdown-height);
  transition: max-height var(--transition-time) var(--transition-timing);
  overflow: hidden;
}

.dropdown-list-wrapper {
  background-color: var(--primary-bg-color);
  border-radius: var(--border-radius);
  border: .1rem solid var(--border-color);
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
}

ul.dropdown-list {
  list-style-type: none;
  position: relative;
}

ul.dropdown-list:before {
  content: "";
  z-index: 0;
  opacity: 0;
  height: var(--list-button-height);
  background-color: var(--button-hover-bg-color);
  transition: all var(--transition-time) linear;
  transform: translateY(var(--translate-value));
  border-radius: var(--border-radius);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

ul.dropdown-list:hover:before, ul.dropdown-list:hover ~ .floating-icon {
  opacity: 1;
}

li.dropdown-list-item {
  z-index: 1;
  opacity: var(--list-opacity);
  transition: opacity .8s var(--transition-timing);
  flex-direction: column;
  display: flex;
  position: relative;
}

.is-active {
  opacity: 1 !important;
}

.rotation {
  transform: rotate(180deg) !important;
}

.dropdown-items {
  background-color: var(--primary-bg-color);
  border: .1rem solid var(--border-color);
  opacity: 0;
  border-radius: 1rem;
  flex-direction: column;
  padding: 1rem 0;
  transition: all .5s;
  display: flex;
  position: absolute;
  top: 7rem;
  right: 4.8rem;
}

.dropdown-items a {
  z-index: 5;
  color: #000;
  transition: all .5s;
  position: relative;
  margin: 0 !important;
  padding: .5rem 2.3rem !important;
}

.dropdown-items a:hover {
  background-color: #222429;
  color: #fff !important;
}

.dropdown-items li {
  padding: 1rem;
}

.dropdown-items button span {
  color: #000;
}

.menu-toggle .bar {
  -o-transition: all .3s ease-in-out;
  background-color: #fff;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all .3s ease-in-out;
  display: block;
}

.menu-toggle {
  z-index: 2;
  justify-self: end;
  margin-right: 25px;
  display: none;
}

.menu-toggle:hover {
  cursor: pointer;
}

#mobile-menu.is-active .bar:nth-child(2) {
  opacity: 0;
}

#mobile-menu.is-active .bar:first-child {
  transform: translateY(8px)rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3) {
  transform: translateY(-8px)rotate(-45deg);
}

.navbar .mobile-active {
  z-index: 0;
  background-color: #000000f5;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding-left: 0;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.navbar .mobile-active li {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.navbar .mobile-active li a {
  width: 100%;
  margin: 1rem;
}

@keyframes loader-1-outter {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes gradbar {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.loader {
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.loader img {
  width: 4.5em;
}

.loader .loader--inner {
  z-index: 1;
  background-color: #ffd748;
  border: 3px solid #ffd748;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1vw;
  height: 1vw;
  display: flex;
  position: relative;
}

.loader .loader--outer {
  z-index: 0;
  background-color: #ffd74848;
  border-radius: 50%;
  width: 1vw;
  height: 1vw;
  margin: auto;
  animation: 2s infinite expand;
  position: absolute;
}

.loader p {
  text-transform: uppercase;
  color: #00000069;
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

#cursor {
  z-index: -1;
  pointer-events: none;
  will-change: transform;
  position: fixed;
  top: 0;
  left: 0;
}

#cursor .cursor--inner {
  z-index: 1;
  background-color: #ffd748;
  border: 3px solid #ffd748;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1vw;
  height: 1vw;
  display: flex;
  position: relative;
  transform: translate(-50%, -50%);
}

#cursor .cursor--outer {
  z-index: 0;
  background-color: #ffd74848;
  border-radius: 50%;
  width: 1vw;
  height: 1vw;
  margin: auto;
  animation: 2s infinite expand;
  position: absolute;
}

@keyframes expand {
  0% {
    opacity: 1;
    width: 1vw;
    height: 1vw;
  }

  100% {
    opacity: 0;
    width: 5vw;
    height: 5vw;
  }
}

p, h1, h2, a {
  font-family: Poppins;
  text-decoration: none;
}

h2 {
  text-transform: uppercase;
  color: #383841;
  font-size: 3em;
  font-weight: 500;
}

h2 span {
  color: #fff;
  background-color: #383841;
  font-size: 1.7em;
  font-weight: bold;
  transition: all .2s ease-in-out;
}

h2 span:hover {
  background-color: #ffd748;
}

img {
  transition: all 1s;
}

.imageUp {
  transform: translateY(-5rem) !important;
}

.imageDown {
  transform: translateY(5rem) !important;
}

.intro-section {
  flex-direction: row-reverse;
  height: 100vh;
  margin-bottom: 20rem;
  display: flex;
  position: relative;
}

.intro-text {
  float: right;
  text-transform: uppercase;
  background-image: linear-gradient(#ff5454, #ff4a4a);
  border-radius: 0 20px;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 65%;
  font-weight: 900;
  display: flex;
  position: relative;
}

.intro-text div {
  transition: all 1s;
}

.intro-text p {
  text-indent: -.9rem;
  margin: 0;
  line-height: 1em;
}

.intro-text .upper p {
  text-indent: -.5rem;
}

.intro-text .upper {
  color: #383841;
  flex-direction: column-reverse;
  height: 8rem;
  font-size: 8em;
  display: flex;
  overflow: hidden;
}

.intro-text .lower {
  color: #fff;
  flex-direction: column;
  height: 16rem;
  font-size: 16em;
  display: flex;
  overflow: hidden;
}

.intro-text .upper div, .intro-text .lower div {
  transform: translateY(0%);
}

.moveDown {
  transform: translate(0, 100%);
}

.moveUp {
  transform: translate(0, -100%);
}

.intro-line {
  z-index: -2;
  width: 25rem;
  height: auto;
  display: flex;
  position: absolute;
  bottom: -80rem;
}

.intro-line div {
  height: 180rem;
}

.intro-line div:first-child {
  z-index: -1;
  background-color: #ffd84a;
  width: 15rem;
}

.intro-line div:nth-child(2) {
  z-index: -2;
  background-color: #ffd84a4d;
  width: 5rem;
}

.intro-line div:nth-child(3) {
  z-index: -2;
  background-color: #ffd84a1e;
  width: 5rem;
}

.about-line {
  z-index: -1;
  width: 25rem;
  height: auto;
  display: flex;
  position: absolute;
  top: 10rem;
  left: 5rem;
}

.about-line div {
  height: 180rem;
}

.about-line div:first-child {
  z-index: -1;
  background-color: #ffd84a;
  width: 15rem;
}

.about-line div:nth-child(2) {
  z-index: -2;
  background-color: #ffd84a4d;
  width: 5rem;
}

.about-line div:nth-child(3) {
  z-index: -2;
  background-color: #ffd84a1e;
  width: 5rem;
}

.purchase {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  margin-bottom: 8rem;
  display: flex;
}

.purchase h2 {
  text-align: center;
  margin-left: 15rem;
  display: inline-block;
}

.purchase a {
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-filter: drop-shadow(0 0 .75rem #1b18182b);
  -ms-filter: drop-shadow(0 0 .75rem #1b18182b);
  background-color: #ff4848;
  border: 1.8px solid #fff0b8;
  border-radius: 1.5rem;
  min-width: 15rem;
  margin-left: 15rem;
  padding: 1.5rem 1.8rem;
  font-family: Poppins;
  font-size: 1.2em;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.purchase a .shine {
  content: "";
  opacity: .5;
  background: #ffffffc7;
  width: 20px;
  height: 200px;
  transition: all .4s;
  animation: 10s 2s infinite shiny;
  display: block;
  position: absolute;
  top: -50px;
  left: -145px;
  transform: rotate(-45deg);
  box-shadow: 0 0 20px 10px #fff;
}

.purchase a:hover .shine {
  left: 110%;
}

.purchase a:hover {
  background-color: #ffe854;
  transition: all .4s;
}

@keyframes shiny {
  0% {
    left: -145px;
  }

  70% {
    left: 200rem;
  }

  100% {
    left: -145px;
  }
}

.intro-section .coverImage img {
  -webkit-filter: drop-shadow(0 0 .75rem #1b18182b);
  -ms-filter: drop-shadow(0 0 .75rem #1b18182b);
  position: absolute;
}

.intro-section .coverImage img.float:nth-child(odd) {
  animation: 15s ease-in-out infinite float;
}

.intro-section .coverImage img.float:nth-child(2) {
  animation: 25s ease-in-out infinite float;
}

.intro-section .coverImage img.float:nth-child(4) {
  animation: 35s ease-in-out infinite floatRotate;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes floatRotate {
  0% {
    transform: translateY(0)rotate(20deg);
  }

  50% {
    transform: translateY(-30px)rotate(30deg);
  }

  100% {
    transform: translateY(0)rotate(20deg);
  }
}

.intro-section .coverImage .delivery {
  z-index: 2;
  -webkit-filter: brightness(1.25);
  -ms-filter: brightness(1.25);
  width: 40rem;
  bottom: 0;
  right: 70rem;
}

.intro-section .coverImage .store {
  z-index: -1;
  -webkit-filter: brightness(.8);
  -ms-filter: blur(10px);
  opacity: 1;
  -ms-filter: brightness(.8);
  width: 40rem;
  bottom: -65rem;
  right: 80rem;
  transform: rotate(10deg);
}

.intro-section .coverImage .gift {
  -webkit-filter: brightness(1.15);
  -ms-filter: brightness(1.15);
  width: 15rem;
  bottom: 2rem;
  right: 25rem;
  transform: rotate(20deg);
}

.intro-section .coverImage .visual {
  z-index: -1;
  width: 30rem;
  bottom: 5rem;
  right: 55rem;
}

.intro-section .coverImage .sneaker {
  -webkit-filter: blur(5px);
  -ms-filter: blur(5px);
  z-index: -1;
  bottom: 5rem;
  right: 20rem;
}

.intro-section .coverImage .starOne {
  width: 25em;
  bottom: 10em;
  right: 18rem;
}

.intro-section .coverImage .starTwo {
  bottom: 15rem;
  left: 45rem;
}

.intro-section .coverImage .starThree {
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  width: 25rem;
  bottom: -25rem;
  right: 45rem;
  transform: rotate(60deg);
}

.intro-section .coverImage .box {
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  z-index: -1;
  opacity: .6;
  width: 15rem;
  bottom: -42rem;
  right: 65rem;
  transform: rotate(60deg);
}

.intro-section .coverImage .cone {
  z-index: -1;
  opacity: .3;
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  width: 10rem;
  bottom: -30rem;
  right: 55rem;
  transform: rotate(90deg);
}

.intro-section .coverImage .setting {
  -webkit-filter: brightness(1.17);
  -ms-filter: brightness(1.17);
  z-index: -1;
  width: 40rem;
  bottom: -5rem;
  right: 30rem;
}

.intro-section .coverImage .bluecredit {
  z-index: -1;
  opacity: .7;
  width: 25rem;
  bottom: -25rem;
  right: 85rem;
}

.intro-section .coverImage .cover {
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  z-index: -2;
  opacity: .4;
  width: 122%;
}

.about-services {
  height: 100vh;
  padding: 15rem;
}

.about-services h2 {
  text-align: end;
}

.about-us {
  background-color: #ff5454;
  border-radius: 2rem;
  justify-content: flex-end;
  align-items: center;
  height: 25rem;
  margin-top: 5rem;
  padding: 1.2em;
  display: flex;
  position: relative;
}

.about-us p {
  color: #fff;
  width: 75rem;
  margin-right: 5rem;
  font-size: 2rem;
  font-weight: 600;
  display: inline;
}

.about-us img {
  height: 43rem;
  position: absolute;
  top: -15rem;
  left: -5.6rem;
}

.about-services {
  position: relative;
}

.about-services div.cover {
  z-index: 2;
  background-color: #fff;
  height: 5rem;
  position: relative;
}

.services {
  margin-top: 5rem;
  transition: all 1s;
  display: flex;
}

.services div {
  -webkit-filter: drop-shadow(0 0 .75rem #1b18182b);
  -ms-filter: drop-shadow(0 0 .75rem #1b18182b);
  background-image: linear-gradient(380deg, #ffa5a5, #ff4a4a, #ff4a4a);
  border-radius: 2rem;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  min-height: 28rem;
  padding: 2rem;
  transition: all 1s;
  display: flex;
  position: relative;
}

.services div:not(.services div:first-child) {
  margin-left: 3rem;
}

.services div:nth-child(2n) {
  background-image: linear-gradient(380deg, #474343, #3a3131, #292222);
  top: 10rem;
}

.services div:nth-child(odd) {
  top: 0;
}

.services div:hover {
  background-image: linear-gradient(380deg, #dec63d, #dabb31, #ffe14c);
  transition: all 1s;
}

.services .aco:hover svg #Path_16 {
  fill: #fff;
  transition: all 1s;
}

.services .aco:hover svg #Path_17 {
  fill: #ffeca6;
  transition: all 1s;
}

.services .seminar:hover svg #Path_6-2 {
  fill: #595963;
  transition: all 1s;
}

.services .guide:hover svg #Path_12 {
  fill: #ffeca6;
  transition: all 1s;
}

.services .guide:hover svg #Path_11 {
  fill: #595963;
  transition: all 1s;
}

.services .guide:hover svg #Path_2, .services .support:hover svg #Path_3 {
  fill: #fff;
  transition: all 1s;
}

.services .support:hover svg #Path_5 {
  fill: #595963;
  transition: all 1s;
}

.services .shipping:hover svg #Path_18 {
  fill: #ffeca6;
  transition: all 1s;
}

.services .shipping:hover svg #Path_20 {
  fill: #595963;
  transition: all 1s;
}

.services .servicesDown {
  top: 10rem !important;
}

.services .servicesUp {
  top: 0 !important;
}

.services div img {
  -webkit-filter: drop-shadow(0 0 .75rem #1b18182b);
  -ms-filter: drop-shadow(0 0 .75rem #1b18182b);
  width: 7rem;
  margin: 2rem 0;
}

.services div svg {
  -webkit-filter: drop-shadow(0 0 .75rem #1b18182b);
  -ms-filter: drop-shadow(0 0 .75rem #1b18182b);
  margin: 2rem 0;
}

.services div p {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
}

.services div p:nth-child(2) {
  margin: .5rem 0;
  font-size: 2rem;
  font-weight: 600;
}

.services div .service-cover {
  z-index: -1;
  background-color: #403f5912;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 2rem;
  right: -2rem;
}

.about-services .element {
  position: absolute;
}

.about-services .box {
  z-index: -1;
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  width: 25rem;
  top: 15rem;
  right: 58rem;
}

.about-services .bell {
  z-index: 1;
  -webkit-filter: blur(10px);
  -ms-filter: blur(10px);
  width: 20rem;
  right: 5rem;
}

.about-services .cart {
  z-index: -1;
  -webkit-filter: brightness(1.1);
  -ms-filter: brightness(1.1);
  width: 15rem;
  bottom: 35rem;
  left: -5rem;
  transform: rotate(320deg);
}

.about-services .fun {
  z-index: -1;
  width: 25rem;
  bottom: 45rem;
  right: -5rem;
}

.review-section {
  margin-top: 33em;
  padding: 0 10em;
}

.faq-section {
  padding-top: 5rem;
}

.faq-section h2 {
  text-align: center;
}

.faq-details {
  background-image: linear-gradient(#ffa5a5, #ff4a4a, #ff4a4a);
  margin-top: 5rem;
  padding: 10rem 15rem 9rem;
  position: relative;
  overflow: hidden;
}

.faq-section .faq-details img {
  z-index: 0;
  opacity: .3;
  width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

.faq-details div p {
  color: #3b3d4171;
  text-align: center;
  padding: 0 5rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.faq-details p:first-child {
  color: #17181c;
  text-align: center;
  margin: .8em;
  font-size: 1.5rem;
  font-weight: 600;
}

.faq-details div {
  -webkit-backdrop-filter: blur(10px);
  -ms-filter: blur(10px);
  cursor: pointer;
  z-index: 1;
  background-color: #ffffffa2;
  border: 1px solid #fff;
  border-radius: 1rem;
  height: 5rem;
  margin-bottom: 2rem;
  transition: all .5s;
  position: relative;
}

.faq-details div:hover {
  background-color: #fff;
}

.faq-details .dropdown-arrow {
  height: 2rem;
  margin: auto;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2rem;
}

.deactivate {
  display: none;
}

.expandDiv {
  height: 15rem !important;
}

footer {
  background-color: #383841;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  padding: 0 2rem;
  display: flex;
}

footer a {
  cursor: pointer;
}

footer img {
  height: 1.5rem;
}

footer a:last-child img {
  height: 1.8rem;
}

footer .infos {
  text-align: center;
  color: #fff;
}

footer .infos p {
  font-size: 1.1rem;
  font-weight: 600;
}

footer .infos .details a {
  pointer-events: initial;
  margin: .5rem;
  text-decoration: none;
  color: #ffffff7f !important;
}

.carousel {
  background-image: linear-gradient(#ff4935, #ff4a4ae7, #f601018a, #ff4a4a00);
  min-height: 25em;
  margin-top: 4em;
}

.testimonial:after {
  content: " ";
  float: left;
  z-index: 99;
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0 !important;
}

.testimonial {
  background-position: center;
  background-size: cover;
  min-height: 375px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  cursor: e-resize;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  height: 20px;
  margin: 0;
  padding: 0 5px;
  font-size: 0;
  position: absolute;
  bottom: 15px;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.testimonial4_indicators .carousel-indicators li {
  text-indent: 0;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  margin: 2px 3px;
  padding: 0;
  display: inline-block;
}

.testimonial4_indicators .carousel-indicators .active {
  background-color: #9dd3af;
  border: none;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  margin: 2px 3px;
  padding: 0;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  opacity: 1;
  cursor: pointer;
  text-shadow: none;
  text-align: center;
  text-transform: uppercase;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 2px solid #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 10px;
  font-weight: 700;
  line-height: 38px;
  transition: all .6s cubic-bezier(.3, 1, 0, 1);
  position: absolute;
  top: 175px;
  bottom: auto;
  overflow: hidden;
}

.testimonial4_control_button .carousel-control.left {
  top: 50%;
  left: 7%;
  right: auto;
}

.testimonial4_control_button .carousel-control.right {
  top: 50%;
  left: auto;
  right: 7%;
}

.testimonial4_control_button .carousel-control.left:hover, .testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  text-align: center;
  width: 550px;
  margin: 30px auto;
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
}

.testimonial4_header h4 {
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  position: relative;
}

.rating {
  color: #fff;
  background-color: #484444;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  display: flex;
  position: absolute;
  top: 2em;
  right: 7em;
}

.rating i {
  color: #fada22;
  font-size: .8em;
}

.carousel-control-prev span, .carousel-control-next span {
  border-radius: .2em;
  padding: .1em;
}

.testimonial4_slide {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
  position: relative;
  inset: 0;
}

.testimonial4_slide img {
  color: #f2f2f2;
  text-align: center;
  -o-box-shadow: 0 0 .78em #0000003b;
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: auto;
  font-size: 18px;
  line-height: 46px;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 .78em #0000003b;
}

.testimonial4_slide p {
  color: #383841;
  margin: 1em .5em;
  padding: 0 2em;
  font-size: 1em;
  line-height: 1.4;
}

.testimonial4_slide h4 {
  color: #fff;
  margin-top: 1em;
  font-size: 1.2em;
}

.testimonial4_slide > div {
  justify-content: center;
  width: 23em;
  margin: auto;
  display: flex;
  position: relative;
}

.testimonial .carousel {
  padding-bottom: 50px;
}

.testimonial .carousel-control-next-icon, .testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}

@media only screen and (width <= 2000px) {
  .intro-section .intro-text .lower {
    height: 14rem;
    font-size: 14rem;
  }

  .intro-section .coverImage .delivery {
    z-index: 2;
    -webkit-filter: brightness(1.25);
    -ms-filter: brightness(1.25);
    width: 38rem;
    bottom: 0;
    right: 45rem;
  }

  .purchase h2 {
    margin-left: 10rem;
  }

  .purchase a {
    margin-top: 4em;
    margin-left: 10rem;
  }

  .intro-section .coverImage .visual {
    z-index: -1;
    width: 25rem;
    bottom: -9rem;
    right: 65rem;
  }

  .intro-section .coverImage .setting {
    width: 30rem;
  }

  .intro-section .coverImage .gift {
    width: 15rem;
    bottom: 2rem;
    right: 20rem;
  }

  .intro-section .coverImage .starTwo {
    left: 35rem;
  }

  .section:not(.intro-section, .faq-section) {
    padding: 10rem;
  }

  .intro-section .coverImage .starThree {
    right: 17rem;
  }

  .intro-section .coverImage .cone {
    right: 35rem;
  }

  .intro-section .coverImage .box {
    right: 45rem;
  }

  .intro-section .coverImage .store {
    width: 35rem;
    bottom: -60rem;
    right: 50rem;
  }

  .about-us img {
    height: 40rem;
  }

  .about-services .box {
    width: 20rem;
    top: 20rem;
    right: 36rem;
  }

  .about-us p {
    width: 60rem;
    margin-right: 0;
    font-size: 1.6rem;
  }

  .about-services .fun {
    width: 25rem;
    bottom: 10rem;
  }

  .services div {
    width: 20rem;
    min-height: 15rem;
  }

  .services div img, .services div svg {
    width: 5rem;
    margin: 1rem 0;
  }

  .services div p:nth-child(2) {
    font-size: 1.5rem;
  }

  .services div p {
    font-size: 1.1rem;
  }

  .partners-section {
    padding-top: 15rem !important;
  }

  .partners-section .partners-details div {
    width: 42%;
  }

  .partners-section .partners-details div p {
    font-size: 1.1rem;
  }
}

@media only screen and (width <= 1750px) {
  .intro-section .intro-text .lower {
    height: 12rem;
    font-size: 12rem;
  }

  .intro-section .intro-text .upper {
    height: 6rem;
    font-size: 6rem;
  }

  .purchase h2, .purchase a {
    margin-left: 5rem;
  }

  .section:not(.intro-section, .faq-section) {
    padding: 5rem;
  }

  .intro-section .coverImage .delivery {
    width: 28rem;
    bottom: -10px;
    right: 45rem;
  }

  .main-button {
    padding: .8rem .3rem;
  }

  .dropdown-items {
    right: 4.4rem;
  }

  .about-us img {
    height: 39rem;
    top: -13rem;
  }

  .about-us p {
    width: 55rem;
  }

  .about-services .bell {
    z-index: 1;
    width: 15rem;
    right: 15rem;
  }
}

@media only screen and (width <= 1550px) {
  h2 {
    font-size: 2em;
  }

  .intro-section .intro-text .upper {
    height: 5rem;
    font-size: 5rem;
  }

  .intro-section .intro-text .lower {
    height: 10rem;
    font-size: 10rem;
  }

  .intro-section .coverImage .delivery {
    width: 30rem;
    bottom: -10px;
    right: 40rem;
  }

  .intro-section .coverImage .visual {
    z-index: -1;
    width: 15rem;
    bottom: 5rem;
    right: 35rem;
  }

  .intro-section .coverImage .gift {
    width: 10rem;
    bottom: 2rem;
    right: 10rem;
  }

  .intro-section .coverImage .setting {
    width: 25rem;
    right: 20rem;
  }

  .intro-section .coverImage .starTwo {
    bottom: 10rem;
    left: 25rem;
  }

  .about-services .box {
    width: 20rem;
    top: 15rem;
    right: 16rem;
  }

  .intro-section .coverImage .store {
    width: 25rem;
    bottom: -45rem;
    right: 50rem;
  }

  .about-us img {
    height: 32rem;
    top: -10rem;
  }

  .about-us p {
    width: 45rem;
  }

  .services div {
    padding: .2rem;
  }

  .services div p:nth-child(2) {
    font-size: 1.1rem;
  }

  .services div .service-cover {
    background-color: #926e6e7e;
    background-image: none;
  }

  .services div p {
    font-size: 1rem;
  }

  .services div img {
    width: 5rem;
  }

  .services div svg {
    width: 4rem;
  }

  .about-services .bell {
    z-index: 1;
    width: 10rem;
    right: 15rem;
  }

  .partners-section {
    padding-top: 25rem !important;
  }

  .partners-section .partners-details > div:nth-child(2n), .partners-section .partners-details > div:nth-child(odd) {
    transform: translate(0);
  }

  .partners-section .partners-details > div {
    opacity: 1;
    width: 45%;
    padding: 1rem;
  }

  .partners-section .partners-details div img {
    z-index: 1;
    border-radius: 5rem;
    width: 5rem;
  }

  .partners-section .partners-details div p {
    font-size: 1rem;
  }
}

@media only screen and (width <= 1100px) {
  h2 {
    font-size: 2rem;
    text-align: center !important;
  }

  #cursor {
    display: none;
  }

  .navbar {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: #07061000;
    justify-content: flex-end;
    align-items: center;
    height: 3.5rem;
    display: flex;
  }

  .navbar .logo {
    display: none;
  }

  .navbar li {
    float: right;
    align-items: center;
    padding: 0;
    list-style-type: none;
    display: flex;
  }

  .navbar li a {
    color: #fff;
    text-transform: uppercase;
    padding: 0;
    font-size: 1.5rem;
  }

  .navbar li a:not(:last-child):hover {
    color: #fff;
    transition: all .5s ease-in-out;
  }

  .navbar .menu-toggle {
    display: block;
  }

  .dropdown-items {
    position: initial;
    margin-top: 1rem;
    padding: 3rem;
  }

  .dropdown-items a {
    padding: 0 !important;
  }

  .menu-toggle {
    justify-self: end;
    margin-right: 2rem;
    display: none;
  }

  .navbar ul {
    display: none;
  }

  .intro-section {
    flex-direction: column;
  }

  .loader img {
    width: 7.3rem;
  }

  .purchase h2 {
    width: 100%;
    display: none;
  }

  .purchase a {
    margin-top: 0 !important;
  }

  .intro-text {
    text-align: center;
    background-image: linear-gradient(#ff6554, #ff4a4ad1, #f601018b, #ff4a4a00);
    border-radius: 0;
    justify-content: flex-end;
    width: 100%;
    height: 60%;
    padding-bottom: 5rem;
  }

  .intro-section .intro-text .upper {
    height: 4rem;
    font-size: 4rem;
  }

  .intro-section .intro-text .lower {
    height: 5rem;
    font-size: 5rem;
  }

  .purchase {
    z-index: 5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0;
    margin-bottom: 18rem;
  }

  .purchase a {
    filter: none;
    -ms-filter: none;
  }

  .purchase h2, .purchase a {
    margin-left: 0;
  }

  .intro-section .coverImage .delivery {
    width: 35rem;
    bottom: -22rem;
    right: -2rem;
  }

  .intro-section .coverImage .setting {
    width: 25rem;
    right: -5rem;
  }

  .intro-section .coverImage .gift {
    bottom: -3rem;
    left: 0;
  }

  .intro-section .coverImage .visual {
    bottom: 5rem;
    left: 15rem;
  }

  .intro-section .coverImage .starOne {
    width: 22em;
    bottom: -20rem;
    right: -4em;
  }

  .section:not(.intro-section, .faq-section) {
    padding: 2rem;
  }

  .about-services {
    height: 100%;
  }

  .about-us img {
    height: 30rem;
    top: -6em;
  }

  .about-us {
    align-items: flex-end;
    height: 40rem;
    margin-top: 8rem;
    padding-top: 4em;
  }

  .about-us p {
    text-align: center;
    width: 45rem;
    margin-bottom: 3rem;
    font-size: 1.2rem;
  }

  .services {
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    transition: all 1s;
    display: flex;
  }

  .services div {
    position: initial;
    width: auto;
    margin-bottom: 3rem;
  }

  .services div:not(.services div:first-child) {
    margin-left: 0;
  }

  .services div .service-cover {
    display: none;
    right: 0;
  }

  .partners-section {
    padding-top: 10rem !important;
  }

  .partners-section .partners-details {
    flex-wrap: initial;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    display: flex;
  }

  .partners-section .partners-details > div {
    opacity: 1;
    width: 100%;
    padding: 1rem;
  }

  .partners-section .partners-details div {
    height: 25rem;
  }

  .partners-section .partners-details .locked p {
    margin: 0;
  }

  .review-section {
    margin-top: 0;
    padding: 0;
  }

  .faq-details {
    margin-top: 5rem;
    padding: 5rem .5rem;
  }

  .faq-details div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 4rem;
    display: flex;
  }

  .faq-details p:first-child {
    margin-bottom: 1rem;
    font-size: .9rem;
    font-weight: 600;
  }

  .faq-details div p {
    color: #3b3d4171;
    text-align: center;
    padding: 0 .3rem;
    font-size: .9rem;
    font-weight: 500;
  }

  .faq-details span {
    display: none;
  }

  .faq-section .faq-details img {
    z-index: 0;
    opacity: .3;
    width: 195%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*# sourceMappingURL=index.3a93f4b5.css.map */
